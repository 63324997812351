import React, { useMemo, useEffect } from "react";
import { SEO } from "components/common/SEO";
import { Layout } from "components/layout";
import { LandingMap } from "components/view/LandingMap";
import { ViewHeader } from "components/view/ViewHeader";
import { useStore } from "store";
import { HireUS } from "components/view/HireUS";
import { isMobile } from "utils/devices";
import { Mobile } from "components/view/Mobile";

export default function IndexPage() {
  const { displayIntro, toggleDisplayIntro } = useStore();
  const isMobileDevice = useMemo(() => isMobile(), []);

  useEffect(() => {
    toggleDisplayIntro(true);
  }, []);

  return (
    <Layout className="home" dark fullscreen gradient collapsed={!displayIntro}>
      <SEO title="Home" />
      <ViewHeader dark />
      {isMobileDevice ? <Mobile /> : <LandingMap />}
      <HireUS />
    </Layout>
  );
}
